import ModalComponent from 'components/elements/Modal';
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import { QRCodeForGetApp } from 'assets/images/qrCodeForGetApp';
import { useTranslation } from 'hooks/useTypedTranslation';
import { logSegmentEvent } from 'utils/analytics';

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MobileAppQrCodeModal = ({ show, setShow }: any) => {
  const { t } = useTranslation('common');

  const handleClose = () => {
    setShow(false);
  };

  const handleMobileStoreLinkClicked = (linkTo: 'app store' | 'play store') => {
    logSegmentEvent('web_app_qr_code_pop_up_link_clicked', { linkTo });
    return true;
  };

  return (
    <ModalComponent open={show} showCloseButton onClose={handleClose} classNames="md:w-card" dialogClassNames="z-2000">
      <div className="flex h-full w-card flex-none flex-col justify-center sm:px-14 sm:py-10 md:px-10 md:py-10">
        <div className="flex flex-col items-center">
          {show && <QRCodeForGetApp className="mb-4 ml-1 mt-1 bg-white" width="230px" height="230px" />}

          <h3 className="mb-4 mt-2 font-ABCDiatype text-2xl font-bold leading-8">{t('Get Speechify for Mobile')}</h3>
          <p className="onboarding-card-body mb-8 leading-6">
            {t('Scan the code with your phone or find us in the')}&nbsp;
            <a
              href="https://apps.apple.com/us/app/speechify-audio-text-reader/id1209815023"
              onClick={handleMobileStoreLinkClicked.bind(null, 'app store')}
              target="_blank"
              rel="noopener"
            >
              {t('App Store')}
            </a>
            &nbsp;{t('or')}&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.cliffweitzman.speechify2"
              onClick={handleMobileStoreLinkClicked.bind(null, 'play store')}
              target="_blank"
              rel="noopener"
            >
              {t('Google Play')}
            </a>
            .
          </p>
        </div>
      </div>
    </ModalComponent>
  );
};

export default MobileAppQrCodeModal;
