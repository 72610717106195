import React from 'react';

// ESLint: 'props' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Maximize(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.35928 6.05005C1.69995 6.05005 1.95545 5.788 1.95545 5.44078V4.89046L1.81787 2.60404L3.54743 4.41876L5.5587 6.44969C5.67007 6.56761 5.8142 6.62002 5.97799 6.62002C6.34486 6.62002 6.62657 6.37762 6.62657 6.01074C6.62657 5.83386 6.56106 5.67662 6.44313 5.5587L4.42531 3.54088L2.60404 1.81787L4.89701 1.9489H5.44733C5.79455 1.9489 6.06315 1.7065 6.06315 1.35273C6.06315 0.998952 5.79455 0.75 5.44733 0.75H1.80477C1.13653 0.75 0.75 1.13653 0.75 1.80477V5.44078C0.75 5.78145 1.0055 6.05005 1.35928 6.05005ZM8.53957 13.25H12.1821C12.8504 13.25 13.2369 12.8635 13.2369 12.1952V8.55922C13.2369 8.21855 12.9814 7.94995 12.6276 7.94995C12.2869 7.94995 12.038 8.212 12.038 8.55922V9.10954L12.169 11.396L10.4395 9.58124L8.4282 7.55031C8.31682 7.43239 8.17269 7.37998 8.00891 7.37998C7.64203 7.37998 7.36688 7.62238 7.36688 7.98926C7.36688 8.16614 7.42584 8.32338 7.54376 8.4413L9.56813 10.4591L11.3829 12.1821L9.08988 12.0511H8.53957C8.19235 12.0511 7.93029 12.2935 7.92374 12.6473C7.92374 13.001 8.19235 13.25 8.53957 13.25Z"
        fill="#587393"
      />
    </svg>
  );
}
