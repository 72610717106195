import React from 'react';
const StudioIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="48" height="48" rx="8" fill="url(#paint0_linear_1_2722)" />
      <path
        d="M31.0002 14.6667L33.1235 18.9134C33.2168 19.1001 33.0768 19.3334 32.8668 19.3334H30.5568C30.1135 19.3334 29.7052 19.0884 29.5185 18.6917L27.5002 14.6667H25.1668L27.2902 18.9134C27.3835 19.1001 27.2435 19.3334 27.0335 19.3334H24.7235C24.2802 19.3334 23.8718 19.0884 23.6852 18.6917L21.6668 14.6667H19.3335L21.4568 18.9134C21.5502 19.1001 21.4102 19.3334 21.2002 19.3334H18.8902C18.4468 19.3334 18.0385 19.0884 17.8402 18.6917L15.8335 14.6667H14.6668C13.3835 14.6667 12.3335 15.7167 12.3335 17.0001V31.0001C12.3335 32.2834 13.3835 33.3334 14.6668 33.3334H33.3335C34.6168 33.3334 35.6668 32.2834 35.6668 31.0001V15.8334C35.6668 15.1917 35.1418 14.6667 34.5002 14.6667H31.0002Z"
        fill="white"
      />
      <path
        d="M40.2486 17.3292C40.3068 17.6001 40.6932 17.6001 40.7514 17.3292L41.0337 16.0144C41.1931 15.2726 41.7726 14.6931 42.5144 14.5337L43.8292 14.2514C44.1001 14.1932 44.1001 13.8068 43.8292 13.7486L42.5144 13.4663C41.7726 13.3069 41.1931 12.7274 41.0337 11.9856L40.7514 10.6708C40.6932 10.3999 40.3068 10.3999 40.2486 10.6708L39.9663 11.9856C39.8069 12.7274 39.2274 13.3069 38.4856 13.4663L37.1708 13.7486C36.8999 13.8068 36.8999 14.1932 37.1708 14.2514L38.4856 14.5337C39.2274 14.6931 39.8069 15.2726 39.9663 16.0144L40.2486 17.3292Z"
        fill="white"
      />
      <path
        d="M36.8883 11.9797C36.9141 12.1 37.0859 12.1 37.1117 11.9797L37.2372 11.3953C37.308 11.0656 37.5656 10.808 37.8953 10.7372L38.4797 10.6117C38.6 10.5859 38.6 10.4141 38.4797 10.3883L37.8953 10.2628C37.5656 10.192 37.308 9.93442 37.2372 9.6047L37.1117 9.02035C37.0859 8.89996 36.9141 8.89996 36.8883 9.02035L36.7628 9.6047C36.692 9.93442 36.4344 10.192 36.1047 10.2628L35.5203 10.3883C35.4 10.4141 35.4 10.5859 35.5203 10.6117L36.1047 10.7372C36.4344 10.808 36.692 11.0656 36.7628 11.3953L36.8883 11.9797Z"
        fill="white"
      />
      <path
        d="M39.1662 9.10974C39.1856 9.20003 39.3144 9.20003 39.3338 9.10974L39.3837 8.87728C39.4368 8.62999 39.63 8.43682 39.8773 8.38372L40.1097 8.3338C40.2 8.31441 40.2 8.18559 40.1097 8.1662L39.8773 8.11628C39.63 8.06318 39.4368 7.87001 39.3837 7.62272L39.3338 7.39026C39.3144 7.29997 39.1856 7.29997 39.1662 7.39026L39.1163 7.62272C39.0632 7.87001 38.87 8.06318 38.6227 8.11628L38.3903 8.1662C38.3 8.18558 38.3 8.31441 38.3903 8.3338L38.6227 8.38372C38.87 8.43682 39.0632 8.62999 39.1163 8.87728L39.1662 9.10974Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_1_2722" x1="-7.68" y1="-1.09315e-06" x2="55.0587" y2="12.4612" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0D22F3" />
          <stop offset="1" stopColor="#7B00F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StudioIcon;
