import React from 'react';

export default function ExclamationCircled(props: $TSFixMe) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 23.5156C13.5781 23.5156 15.0586 23.2148 16.4414 22.6133C17.832 22.0117 19.0586 21.1797 20.1211 20.1172C21.1836 19.0625 22.0156 17.8398 22.6172 16.4492C23.2188 15.0586 23.5195 13.5742 23.5195 11.9961C23.5195 10.418 23.2148 8.9375 22.6055 7.55469C22.0039 6.16406 21.1719 4.9375 20.1094 3.875C19.0547 2.8125 17.832 1.98438 16.4414 1.39062C15.0508 0.789063 13.5664 0.488281 11.9883 0.488281C10.418 0.488281 8.9375 0.789063 7.54688 1.39062C6.15625 1.98438 4.92969 2.8125 3.86719 3.875C2.8125 4.9375 1.98438 6.16406 1.38281 7.55469C0.78125 8.9375 0.480469 10.418 0.480469 11.9961C0.480469 13.5742 0.78125 15.0586 1.38281 16.4492C1.98438 17.8398 2.8125 19.0625 3.86719 20.1172C4.92969 21.1797 6.15625 22.0117 7.54688 22.6133C8.9375 23.2148 10.4219 23.5156 12 23.5156ZM12 13.8477C11.2891 13.8477 10.9219 13.4922 10.8984 12.7812L10.7344 7.39062C10.7188 7.03125 10.8281 6.73828 11.0625 6.51172C11.2969 6.28516 11.6055 6.17188 11.9883 6.17188C12.3633 6.17188 12.668 6.28906 12.9023 6.52344C13.1367 6.75 13.25 7.03906 13.2422 7.39062L13.0664 12.7695C13.043 13.4883 12.6875 13.8477 12 13.8477ZM12 17.7148C11.6094 17.7148 11.2734 17.5938 10.9922 17.3516C10.7188 17.1016 10.582 16.7891 10.582 16.4141C10.582 16.0391 10.7188 15.7305 10.9922 15.4883C11.2734 15.2383 11.6094 15.1133 12 15.1133C12.3906 15.1133 12.7227 15.2383 12.9961 15.4883C13.2695 15.7305 13.4062 16.0391 13.4062 16.4141C13.4062 16.7969 13.2656 17.1094 12.9844 17.3516C12.7109 17.5938 12.3828 17.7148 12 17.7148Z"
        fill="#14202E"
      />
    </svg>
  );
}
