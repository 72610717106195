import React from 'react';

export default function PinIcon(props: $TSFixMe) {
  return (
    <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 8.75V2.5H15C15.6875 2.5 16.25 1.9375 16.25 1.25C16.25 0.5625 15.6875 0 15 0H2.5C1.8125 0 1.25 0.5625 1.25 1.25C1.25 1.9375 1.8125 2.5 2.5 2.5H3.75V8.75C3.75 10.825 2.075 12.5 0 12.5V15H7.4625V23.75L8.7125 25L9.9625 23.75V15H17.5V12.5C15.425 12.5 13.75 10.825 13.75 8.75Z"
        fill="#4B4D52"
      />
    </svg>
  );
}
