import Modal from 'components/elements/Modal';
import { IUser } from 'interfaces';
import React from 'react';
import { useSelector } from 'store';

import { ReferralUser, useReferralWidget } from 'lib/referrals';

const getFirstAndLastName = (displayName: string) => {
  const [firstName, lastName] = displayName.split(' ').filter(x => x && x !== '');

  return {
    firstName,
    lastName
  };
};

export const getReferralUser = (user: IUser): ReferralUser => {
  const { firstName, lastName } = getFirstAndLastName(user.displayName ?? '');
  return {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    id: user.uid,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    accountId: user.uid,
    // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    email: user.email,
    locale: navigator.language.replace('-', '_'),
    firstName,
    lastName
  };
};

export const SaasquatchWidget = ({ user }: { user: IUser }) => {
  useReferralWidget(getReferralUser(user));

  return <div className="squatchembed"></div>;
};

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReferralModal = ({ open, onClose }: any) => {
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const user = useSelector<IUser>(state => state.auth.user);

  return (
    <Modal classNames="p-4" open={open} onClose={onClose} style={{ maxHeight: '800px', minHeight: '300px', minWidth: '620px', overflow: 'auto' }}>
      <SaasquatchWidget user={user} />
    </Modal>
  );
};

export default ReferralModal;
