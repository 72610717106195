import Button from 'components/elements/Button';
import { useEffect } from 'react';

import { CloseIcon } from 'assets/icons/close-2';
import StudioIcon from 'assets/icons/studio-icon';
import { logSegmentEvent } from 'utils/analytics';

export default function StudioSidebarBanner({
  positionUp,
  isOpen,
  onClick,
  onClose
}: {
  positionUp: boolean;
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
}) {
  useEffect(() => {
    if (!isOpen) return;
    logSegmentEvent('web_app_studio_sidebar_banner_shown');
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="absolute bottom-12 flex w-full flex-col rounded-xl bg-electric-200 px-5 py-4"
      style={{ maxWidth: '250px', ...(positionUp && { marginBottom: '6rem' }) }}
    >
      <CloseIcon onClick={onClose} className="absolute right-4 top-3 h-4 w-4 cursor-pointer fill-glass-500 hover:fill-glass-400" />
      <div>
        <StudioIcon />
      </div>
      <h3 className="mt-3 font-ABCDiatype text-lg font-bold">Try Speechify Studio!</h3>
      <p className="font-ABCDiatype text-sm font-normal text-glass-700">You can download high-quality voice overs, or even clone your own voice.</p>
      <div className="mt-4">
        <Button onClick={onClick} full className="text-base font-bold">
          Open Studio
        </Button>
      </div>
    </div>
  );
}
