import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { getSDK } from 'modules/sdk/lib';

let isPreloaded = false;

export async function preloadNewListeningExperience() {
  if (typeof window === 'undefined' || isPreloaded) return;

  PSPDFKitFacade.preload();

  const [sdk, voiceModule, speedModule] = await Promise.all([
    getSDK(),
    import('../../voices/stores/actions/fetchInitialVoice'),
    import('modules/speed/store/speedStore')
  ]);

  await Promise.all([sdk.voice.getAllVoices()]);
  sdk.voice.getAllVoices();
  voiceModule.fetchInitialVoice();
  speedModule.fetchInitialSpeedInWPM();

  isPreloaded = true;
}
