import React from 'react';

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChecklistProgress = ({ steps, completedSteps }: any) => {
  return (
    <div className="progress-bar">
      {Array.from(Array(steps).keys()).map((step, index) => {
        return <span key={index} className={`bar-item ${index < completedSteps && 'item-filled'}`}></span>;
      })}
    </div>
  );
};
