import { useCallback, useEffect, useMemo, useState } from 'react';

export const PAGE_SIZE = 25;

type usePaginationStateReturnType = {
  page: number;
  pageCount: number;
  renderStartRange: number;
  renderEndRange: number;
  setCurrentPage: (page: number) => void;
  handlePageNumberChange: (page: number) => void;
};

export function usePaginationState(items: $TSFixMe[], folderId: string | undefined, itemCount: number, enabled: boolean = false): usePaginationStateReturnType {
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [folderId]);

  const handlePageNumberChange = useCallback(
    (pageTo: number) => {
      setCurrentPage(pageTo);
    },
    // ESLint: React Hook useCallback has unnecessary dependencies: 'currentPage', 'folderId', 'itemCount', and 'items'. Either exclude them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, itemCount, folderId, currentPage]
  );

  const pageCount = useMemo(() => {
    return Math.ceil(itemCount / PAGE_SIZE);
  }, [itemCount]);

  const renderStartRange = useMemo(() => {
    const start = (currentPage - 1) * PAGE_SIZE;
    return start;
  }, [currentPage]);

  const renderEndRange = useMemo(() => {
    const end = currentPage * PAGE_SIZE;
    return end;
  }, [currentPage]);

  if (!enabled) {
    return { renderStartRange: 0, renderEndRange: items?.length || 0, page: 1, setCurrentPage, handlePageNumberChange: () => {}, pageCount };
  } else {
    return { renderStartRange, renderEndRange, page: currentPage, setCurrentPage, handlePageNumberChange, pageCount };
  }
}

export default usePaginationState;
