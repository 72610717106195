import ModalComponent from 'components/elements/Modal';
import React from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ExtensionListeningPromptModal = ({ show, setShow }: any) => {
  const { t } = useTranslation('common');

  const handleClose = () => {
    setShow(false);
  };

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpen = (url: any) => {
    window.open(url, '_blank');
  };

  return (
    <ModalComponent open={show} showCloseButton onClose={handleClose} classNames="md:w-xlCard sm:max-w-xlCard" dialogClassNames="z-2000">
      <div className="flex h-full w-full flex-none flex-col items-center justify-center sm:px-14 sm:py-10 md:p-10">
        <h1 className="mb-6 font-ABCDiatype text-2xl font-bold">{t('What would you like to listen to?')}</h1>
        <div className="mb-4 flex w-full justify-between">
          <div
            className="flex h-32 w-44 cursor-pointer flex-col items-center justify-center rounded-2xl border border-solid border-gray-200 hover:border-primaryTint"
            onClick={handleOpen.bind(null, 'https://docs.google.com')}
          >
            <div className="h-11">
              <img src="/icons/GoogleDoc/GoogleDoc.svg" />
            </div>
            <p className="font-ABCDiatype text-lg font-semibold">{t('Google Doc')}</p>
          </div>
          <div
            className="flex h-32 w-44 cursor-pointer flex-col items-center justify-center rounded-2xl border border-solid border-gray-200 hover:border-primaryTint"
            onClick={handleOpen.bind(null, 'https://mail.google.com')}
          >
            <div className="h-11">
              <img src="/icons/Gmail/Gmail.svg" />
            </div>
            <p className="font-ABCDiatype text-lg font-semibold">{t('Gmail')}</p>
          </div>
          <div
            className="flex h-32 w-44 cursor-pointer flex-col items-center justify-center rounded-2xl border border-solid border-gray-200 hover:border-primaryTint"
            onClick={handleOpen.bind(null, 'https://speechify.com/blog/audiobooks')}
          >
            <div className="h-11">
              <img src="/icons/WebArticle/WebArticle.svg" />
            </div>
            <p className="font-ABCDiatype text-lg font-semibold">{t('Web Article')}</p>
          </div>
        </div>
        <p className="text-center font-ABCDiatype text-sm">Or go to any page and press the extension beside the address bar</p>
      </div>
    </ModalComponent>
  );
};

export default ExtensionListeningPromptModal;
