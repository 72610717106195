import React from 'react';

import Maximize from 'assets/icons/maximize';
import { useTranslation } from 'hooks/useTypedTranslation';

import { ChecklistProgress } from './ChecklistProgress';

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChecklistMinimized = ({ revampedChecklistItemsList, onClick }: any) => {
  const { t } = useTranslation('common');

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const completeCount = revampedChecklistItemsList?.filter((item: any) => item.done).length;
  const completePercent = Math.round((completeCount / revampedChecklistItemsList?.length) * 100);
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nextUndone = revampedChecklistItemsList.find((item: any) => !item.done);

  return (
    <div className="checklist-header mt-3 h-[120px] w-[193px] rounded-lg rounded-b-none">
      <div className="flex items-center justify-between">
        <h1 className="border-bottom font-['ABCDiatype'] text-base font-bold text-[#1C2C40] ">{t('Get Started')}</h1>
        <div className="cursor-pointer" onClick={onClick}>
          <Maximize className="w-3.5" />
        </div>
      </div>
      <ChecklistProgress steps={revampedChecklistItemsList?.length} completedSteps={completeCount} />
      <div>
        <p className="text-xs font-normal">
          {completePercent}% -{nextUndone && t('Next')}, {nextUndone?.label}
        </p>
      </div>
    </div>
  );
};
