import React from 'react';

export default function ExtensionPuzzleIcon(props: $TSFixMe) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42612 4.40816C7.42612 1.46193 8.88804 0 10.6914 0C12.4948 0 13.9567 1.46193 13.9567 4.40816H17.222C19.0254 4.40816 20.4873 5.87008 20.4873 7.67347V10.9388C23.4336 10.9388 24.8955 12.4007 24.8955 14.2041C24.8955 16.0075 23.4336 17.4694 20.4873 17.4694V20.7347C20.4873 22.5381 19.0254 24 17.222 24H13.9567C13.9567 21.0538 12.4948 19.5918 10.6914 19.5918C8.88804 19.5918 7.42612 21.0538 7.42612 24H4.16081C2.35743 24 0.895508 22.5381 0.895508 20.7347V17.4694C3.84174 17.4694 5.30367 16.0075 5.30367 14.2041C5.30367 12.4007 3.84174 10.9388 0.895508 10.9388V7.67347C0.895508 5.87008 2.35743 4.40816 4.16081 4.40816H7.42612Z"
        fill="#4B4D52"
      />
    </svg>
  );
}
