import Button from 'components/elements/Button';
import useTranslation from 'next-translate/useTranslation';
import React, { MouseEventHandler } from 'react';

type Props = {
  variant: 'subItem' | 'modal';
  onClick: MouseEventHandler<HTMLButtonElement>;
  setModal?: React.Dispatch<React.SetStateAction<string>>;
} & React.ComponentProps<'div'>;

function InstallChromeExtension({ variant, onClick }: Props) {
  const { t } = useTranslation('common');
  return (
    <div>
      <div className={variant === 'subItem' ? 'checklist-subitem-wrapper' : 'checklist-modal-wrapper'}>
        {variant === 'modal' && <h4 className=" text-xl font-bold">{t('Install Speechify Extension')}</h4>}
        <p>{t('Install the Speechify extension to listen to any webpages and to unlock superpower on Google doc and Gmail')}</p>
        {/* ESLint: Do not pass children as props */}
        {/* eslint-disable-next-line react/no-children-prop */}
        <Button full children={t('Install Speechify Extension')} onClick={onClick} className="text-base font-bold  " />
      </div>
    </div>
  );
}

export default InstallChromeExtension;
