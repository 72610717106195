import { IUser } from 'interfaces';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'store';
import { useLocalStorage } from 'usehooks-ts';

import * as speechify from 'lib/speechify';
import { logSegmentEvent } from 'utils/analytics';

export const useStudioBanner = (isChecklistCompleted?: boolean, userCreated24HoursAgo?: boolean) => {
  const [showStudioBanner, setShowStudioBanner] = useState<'sidebar' | 'top' | null>(null);
  const [studioSidebarBannerClosed, setStudioSidebarBannerClosed] = useLocalStorage('studioSidebarBannerClosed', false);
  const [studioTopBannerClosed, setStudioTopBannerClosed] = useLocalStorage('studioTopBannerClosed', false);
  const [canvasBannerClosed] = useLocalStorage('canvas_lms_banner_closed', false);
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);

  const isOnTrial = speechify.isOnTrial(currentUser);
  const hasSubscription = speechify.hasSubscription(currentUser);

  const closeStudioBanner = useCallback(() => {
    if (showStudioBanner === 'top') {
      setStudioTopBannerClosed(true);
      logSegmentEvent('web_app_studio_top_banner_closed');
    }
    if (showStudioBanner === 'sidebar') {
      setStudioSidebarBannerClosed(true);
      logSegmentEvent('web_app_studio_sidebar_banner_closed');
    }
    setShowStudioBanner(null);
    // ESLint: React Hook useCallback has missing dependencies: 'setStudioSidebarBannerClosed' and 'setStudioTopBannerClosed'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStudioBanner]);

  const handleStudioBannerClick = useCallback(() => {
    window.open('https://studio.speechify.com/onboarding/voiceovers?fromWebApp=true', '_blank');
    showStudioBanner === 'top' ? logSegmentEvent('web_app_studio_top_banner_clicked') : logSegmentEvent('web_app_studio_sidebar_banner_clicked');
  }, [showStudioBanner]);

  useEffect(() => {
    if (!isOnTrial && !currentUser?.hasStudioSubscription) {
      if (hasSubscription && !studioTopBannerClosed) {
        setShowStudioBanner('top');
      }
      const isCanvasNotificationHidden = canvasBannerClosed === true;
      if (!hasSubscription && !studioSidebarBannerClosed && isCanvasNotificationHidden && (isChecklistCompleted || userCreated24HoursAgo)) {
        setShowStudioBanner('sidebar');
      }
    }
  }, [
    isOnTrial,
    studioTopBannerClosed,
    studioSidebarBannerClosed,
    hasSubscription,
    isChecklistCompleted,
    userCreated24HoursAgo,
    canvasBannerClosed,
    currentUser?.hasStudioSubscription
  ]);

  return {
    showStudioBanner,
    handleStudioBannerClick,
    closeStudioBanner
  };
};
