import React from 'react';
export default function MinimizeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="#ff0000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.85271 7.24098H6.02355C6.79008 7.24098 7.24098 6.79008 7.24098 6.02355V1.86022C7.24098 1.46944 6.9479 1.16132 6.54208 1.16132C6.14379 1.16132 5.85822 1.46192 5.85822 1.86022V2.49148L6.01603 5.11423L4.03206 3.03257L1.72495 0.702906C1.59719 0.567635 1.42435 0.5 1.24399 0.5C0.815631 0.5 0.5 0.785571 0.5 1.20641C0.5 1.4018 0.57515 1.58968 0.710421 1.72495L3.02505 4.03958L5.11423 6.01603L2.48397 5.86573H1.85271C1.45441 5.86573 1.14629 6.14379 1.14629 6.5496C1.14629 6.9479 1.44689 7.24098 1.85271 7.24098ZM9.05962 14.8687C9.45792 14.8687 9.74349 14.5757 9.74349 14.1698V13.4559L9.58567 10.8407L11.5696 12.9223L13.9294 15.2971C14.0571 15.4324 14.23 15.5 14.4103 15.5C14.8312 15.5 15.1543 15.2144 15.1543 14.7936C15.1543 14.5982 15.0792 14.4103 14.9439 14.2751L12.5767 11.9078L10.495 9.93136L13.1177 10.0817H13.8317C14.23 10.0817 14.5381 9.80361 14.5381 9.40531C14.5381 8.9995 14.2375 8.71393 13.8317 8.71393H9.57816C8.81162 8.71393 8.36824 9.15731 8.36824 9.92385V14.1698C8.36824 14.5681 8.66132 14.8687 9.05962 14.8687Z"
        fill="#364A63"
      />
    </svg>
  );
}
