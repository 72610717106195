import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, LightBulbIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import MobileAppQrCodeModal from 'components/ecosystem/mobileAppQrCodeModal';
import Button from 'components/elements/Button';
import { DailyListeningGoalPicker } from 'components/gamification/DailyListeningGoalPicker';
import { useIsGamificationEnabled } from 'components/gamification/hooks/useIsGamificationEnabled';
import AddYourFirstItem from 'components/progress-details/AddYourFirstItem';
import DownloadMobileApp from 'components/progress-details/DownloadMobileApp';
import InstallChromeExtension from 'components/progress-details/InstallChromeExtension';
import ListenToYourDoc from 'components/progress-details/ListenToYourDoc';
import PinChromeExtension from 'components/progress-details/PinChromeExtension';
import { IUser } from 'interfaces';
import React, { Fragment, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { RootState, useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';

import { Cross } from 'assets/icons/cross';
import Maximize from 'assets/icons/maximize';
import Minimize from 'assets/icons/minimize';
import { Puzzle } from 'assets/images/puzzle';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { auth } from 'lib/speechify';
import { actions as authActions } from 'store/auth';
import { actions as libraryActions } from 'store/library';
import { logSegmentEvent } from 'utils/analytics';
import { setCustomAccountSetting } from 'utils/baseAccountSettings';
import { checkExtensionInstalled, getRecommendedExtensionToInstall, isBrowserSupported } from 'utils/extension';

import { ChecklistMinimized } from './ChecklistMinimized';
import { ChecklistProgress } from './ChecklistProgress';
import ExtensionListeningPromptModal from './ExtensionListeningPromptModal';

type ChecklistItemProps = {
  children: string;
  done?: boolean;
  isCTA?: boolean;
  onClick?: (e: MouseEvent) => void;
};

type RevampedChecklistItemProps = {
  children: boolean | string | JSX.Element;
  done?: boolean;
  isFirstUndone: boolean;
  label: string;
  isCTA?: boolean;
  isExpanded?: boolean;
  subtext?: string | JSX.Element;
  onClick?: (e: MouseEvent) => void;
};

const ChecklistItem = ({ children, done, onClick, isCTA }: ChecklistItemProps) => {
  return (
    <div className="mb-3 flex items-center">
      <div className={twMerge('mr-3 h-4 w-4 rounded-full border border-solid border-yellow-400', isCTA ? 'mr-1.5' : '')}>
        {done && <CheckCircleIcon fill="rgba(255, 176, 26, 1)" className="relative h-5 w-5" style={{ top: '-3px', left: '-3px' }} />}
      </div>
      <div
        className={twMerge(
          'flex flex-grow cursor-pointer items-center justify-between self-stretch rounded border border-solid border-yellow-400',
          isCTA ? 'border-none p-0' : 'h-12 p-3 '
        )}
        onClick={onClick}
      >
        <p className={twMerge('font-ABCDiatype', done ? 'line-through opacity-40' : '', isCTA ? 'text-sm' : 'text-base')}>{children}</p>
        <ChevronRightIcon className="h-4" />
      </div>
    </div>
  );
};

const RevampedChecklistItem = ({ children, done, isFirstUndone, onClick, isCTA, isExpanded, label }: RevampedChecklistItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const autoScrollTo = (ref: any) => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    });
  };

  return (
    <div ref={ref} className="mb-6 flex flex-col">
      <div className="flex items-center justify-between ">
        <div
          className={twMerge('mr-[0.6rem] h-4 w-4 rounded-full border border-solid border-[#587393]', isFirstUndone ? 'border-[red]' : '')}
          style={isFirstUndone ? { border: '2px solid #6a79fd', background: '#e6e8ff' } : {}}
        >
          {done && <CheckCircleIcon fill="rgb(119, 143, 173)" className="relative h-5 w-5" style={{ top: '-3px', left: '-3px' }} />}
        </div>
        <div
          className={twMerge(
            'flex flex-grow cursor-pointer items-center justify-between self-stretch rounded border border-solid border-yellow-400',
            isCTA ? 'border-none p-0' : 'h-12 p-3 '
          )}
          onClick={e => {
            // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onClick(e);
            if (!done) autoScrollTo(ref);
          }}
        >
          <p
            className={twMerge('text-base', done ? '' : '', isCTA ? 'text-sm' : 'text-base')}
            style={{
              color: done ? '#778FAD' : isFirstUndone ? '#1C2C40' : '#364a63',
              fontWeight: isFirstUndone ? '700' : '400'
            }}
          >
            {label}
          </p>
          {!done && <>{isExpanded ? <ChevronUpIcon className="h-4 text-[#587393] " /> : <ChevronDownIcon className="h-4 text-[#587393]  " />}</>}
        </div>
      </div>
      {isExpanded && children}
    </div>
  );
};

export function useChecklistData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const { items } = useSelector((state: RootState) => state.library);
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const user = useSelector<IUser>(state => state.auth.user);

  const [showExtensionListeningPrompt, setShowExtensionListeningPrompt] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);

  const isGamificationEnabled = useIsGamificationEnabled();

  const recommendedExtension = useMemo(() => getRecommendedExtensionToInstall(), []);

  useEffect(() => {
    const checkExtensionInstall = async () => {
      if (await checkExtensionInstalled()) {
        dispatch(authActions.setExtensionInstalled(true));
        setCustomAccountSetting('extensionInstalled', 'true');
      }
    };
    checkExtensionInstall();
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetShow = (show: boolean) => {
    setShowExtensionListeningPrompt(show);
  };

  const getExtensionListeningPromptModal = () => <ExtensionListeningPromptModal show={showExtensionListeningPrompt} setShow={handleSetShow} />;

  const getModals = () => (
    <Fragment>
      <MobileAppQrCodeModal show={showQrCode} setShow={setShowQrCode} />
      {getExtensionListeningPromptModal()}
    </Fragment>
  );

  const handleAddItemClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(libraryActions.showAddNewWithBackdrop());
    logSegmentEvent('web_app_activation_checklist_item_clicked', { action: 'upload file' });
  };

  const handleInstallExtensionClick = () => {
    window.open(recommendedExtension.extensionStoreUrl, '_blank');
    logSegmentEvent('web_app_activation_checklist_item_clicked', { action: 'install chrome' });
  };

  const handleRevampedExtClick = () => {
    window.open(recommendedExtension.extensionStoreUrl, '_blank');
    dispatch(authActions.setExtensionInstalled(true));
    setCustomAccountSetting('extensionInstalled', 'true');
    logSegmentEvent('web_app_activation_checklist_item_clicked', { action: 'install chrome' });
  };

  const handleInstallMobileAppClick = async () => {
    navigate('/settings?tab=Apps');
    dispatch(authActions.setMobileAppInstalled(true));
    await setCustomAccountSetting('mobileAppInstalled', 'true');
    logSegmentEvent('web_app_activation_checklist_item_clicked', { action: 'download mobile' });
  };

  const handleExtensionPinned = async () => {
    await setCustomAccountSetting('extensionPinned', 'true');
    dispatch(authActions.setExtensionPinned(true));
    logSegmentEvent('web_app_activation_checklist_item_clicked', { action: 'extension pinned' });
  };

  const handleListenToDoc = async () => {
    await setCustomAccountSetting('redirectGoogleDoc', 'true');
    dispatch(authActions.setGoogleDocsRedirect(true));
    window.open('https://docs.google.com/document/d/1L1SX0BUB7EgoDAx0cRfddHGs2-xPXxEqgRG02fUFcJI/edit?usp=sharing', '_blank');
    logSegmentEvent('web_app_activation_checklist_item_clicked', { action: 'listen to google doc' });
  };

  const checklistItemsList = [
    {
      show: true,
      label: t('Add your first document'),
      done: items?.length > 0,
      onClick: handleAddItemClick
    },
    {
      show: isBrowserSupported(),
      label: t('Install Speechify extension'),
      done: user.extensionInstalled,
      onClick: handleInstallExtensionClick
    },
    {
      show: true,
      label: t('Use Speechify on other devices'),
      done: user.mobileAppInstalled,
      onClick: handleInstallMobileAppClick
    }
  ];

  const revampedChecklistItemsListUnfiltered = [
    {
      show: true,
      label: t('Create an account'),
      subtext: <></>,
      done: !user.isAnonymous
    },
    {
      show: isBrowserSupported(),
      label: t('Install Speechify extension'),
      done: user.extensionInstalled,
      subtext: user.extensionInstalled ? null : <InstallChromeExtension onClick={handleRevampedExtClick} variant="subItem" />
    },
    {
      show: isBrowserSupported(),
      label: t('Pin Speechify extension'),
      subtext: user.extensionPinned ? null : <PinChromeExtension onClick={handleExtensionPinned} variant="subItem" />,
      done: user.extensionPinned
    },
    {
      show: true,
      label: t('Add your first document'),
      done: user.fileUploaded,
      subtext: user.fileUploaded ? null : <AddYourFirstItem variant="subItem" />
    },
    {
      show: true,
      label: t('Listen to your Google doc'),
      subtext: user.redirectGoogleDoc ? null : <ListenToYourDoc variant="subItem" onClick={handleListenToDoc} />,
      done: user.redirectGoogleDoc
    },
    {
      show: true,
      label: t('Use Speechify on other devices'),
      subtext: user.mobileAppInstalled ? null : <DownloadMobileApp variant="subItem" onClick={handleInstallMobileAppClick} />,
      done: user.mobileAppInstalled
    },
    {
      show: isGamificationEnabled,
      label: 'Set a daily goal',
      subtext: user.hasSetDailyListeningGoal ? null : <DailyListeningGoalPicker type="onboardingStep" />,
      done: user.hasSetDailyListeningGoal
    }
  ];

  const revampedChecklistItemsList = revampedChecklistItemsListUnfiltered.filter(item => item.show);

  return {
    getModals,
    checklistItemsList,
    revampedChecklistItemsList
  };
}

const Checklist = () => {
  const { t } = useTranslation('common');
  const displayName = auth?.currentUser?.displayName?.split(' ')[0];

  const { getModals, checklistItemsList } = useChecklistData();

  return (
    <div className="checklist-border relative">
      {getModals()}
      <div className="flex items-center justify-between rounded-xl bg-yellow-100 p-11">
        <div>
          <h1 className="mb-4 font-recoleta text-[2rem] leading-10 font-semibold">
            {t('Welcome to your library')}, {displayName}
          </h1>
          <p className="mb-4 text-sm">{t('Here is your quick start guide:')}</p>
          <div>
            {checklistItemsList.map((item, index) =>
              item.show ? (
                // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                <ChecklistItem done={item.done} key={`item-${index}`} onClick={item.onClick}>
                  {item.label}
                </ChecklistItem>
              ) : null
            )}
          </div>
        </div>
        <div>
          <Puzzle keyPuzzleProps={{ fill: '#EA3223' }} />
        </div>
      </div>
    </div>
  );
};

export const ChecklistCTA = () => {
  const { getModals, checklistItemsList } = useChecklistData();
  const { t } = useTranslation('common');
  const incompleteChecklistItems = useMemo(() => checklistItemsList.filter(item => !item.done && item.show), [checklistItemsList]);

  const [open, setOpen] = useState(false);
  const [hasToggled, setToggled] = useState(false);

  useEffect(() => {
    if (!hasToggled) {
      setOpen(incompleteChecklistItems.length > 0);
    }
    // ESLint: React Hook useEffect has missing dependencies: 'hasToggled' and 'incompleteChecklistItems.length'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistItemsList]);

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleVisibility = (show: any) => {
    setOpen(show);
    setToggled(true);
    logSegmentEvent('web_app_activation_checklist_visibilty_action', { action: show ? 'show' : 'hide' });
  };

  return (
    <div>
      {open && (
        <div className="relative bottom-5 left-5 z-[99] w-full pr-5">
          <div className="checklist-border w-full">
            <div className="float-right cursor-pointer p-2" onClick={() => handleVisibility(false)}>
              <Cross className="w-2 text-secondaryTint" />
            </div>
            {getModals()}
            <div className="rounded-lg bg-yellow-100 px-3 py-4">
              <h1 className="mb-4 font-recoleta text-base font-semibold">{t('Get Started')}</h1>
              <div>
                {checklistItemsList.map((item, index) =>
                  item.show ? (
                    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    <ChecklistItem done={item.done} key={`item-${index}`} onClick={item.onClick} isCTA>
                      {item.label}
                    </ChecklistItem>
                  ) : null
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        onClick={handleVisibility.bind(null, !open)}
        className="relative z-30 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full"
        style={{
          background: open ? 'radial-gradient(131.56% 190.6% at 100% -40.28%, #FFCA66 28.89%, #FC7377 61.45%, #6A79FD 100%)' : '#587393'
        }}
      >
        {!open && incompleteChecklistItems.length > 0 && (
          <div className="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-600 text-xs text-white">
            {incompleteChecklistItems.length}
          </div>
        )}
        <LightBulbIcon className="w-6 text-white" />
      </div>
    </div>
  );
};

export const ChecklistCTARevamped = () => {
  const dispatch = useDispatch();
  const { getModals, revampedChecklistItemsList } = useChecklistData();
  const { t } = useTranslation('common');

  const [showNudge, setShowNudge] = useState(true);
  const [expanded, setExpanded] = useState(() => revampedChecklistItemsList.find(item => !item.done)?.label);

  const maximizeChecklist = useSelector(state => state.library.maximizeChecklist);
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const user = useSelector<IUser>(state => state.auth.user);

  useEffect(() => {
    setShowNudge(!user.clickedStartListening);
  }, [user.clickedStartListening]);

  const handleVisibility = (show: boolean) => {
    dispatch(libraryActions.maximizeChecklist(show));

    logSegmentEvent('web_app_activation_checklist_visibilty_action', { action: show ? 'show' : 'hide' });

    const headerProgress = document.querySelector('.default-header');

    if (headerProgress) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      headerProgress.parentElement.style.display = show ? 'none' : 'grid';
    }
  };

  const firstUndoneIndex = revampedChecklistItemsList.findIndex(item => !item.done);

  useEffect(() => {
    if (revampedChecklistItemsList.filter(i => !i.done).length) {
      dispatch(libraryActions.maximizeChecklist(true));
    } else {
      if (!user.clickedStartListening) {
        ifChecklistCompleted();
      }
    }
    // ESLint: React Hook useEffect has missing dependencies: 'dispatch', 'ifChecklistCompleted', 'revampedChecklistItemsList', and 'user.clickedStartListening'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ifChecklistCompleted = async () => {
    setShowNudge(false);
    await setCustomAccountSetting('clickedStartListening', 'true');
    dispatch(authActions.setClickedStartListening());
  };

  return (
    <>
      {' '}
      {showNudge && (
        <div className="fixed bottom-7 z-[99]">
          {maximizeChecklist && (
            <div className="checklist-maximized bottom-11 z-[99] w-80 pr-5">
              <div className="w-full">
                {getModals()}
                <div className="checklist-wrapper overflow-hidden rounded-lg bg-white ">
                  <div className="checklist-header">
                    <div className="align-items-center mb-4 flex justify-between">
                      <p className="border-bottom text-base font-bold text-[#1c2c40] " style={{ fontSize: showNudge ? '20px' : '' }}>
                        {t('Get Started')}
                      </p>
                      <div className="cursor-pointer" onClick={() => handleVisibility(false)}>
                        {showNudge ? <Minimize className="w-3.5" /> : <Maximize />}
                      </div>
                    </div>
                    <ChecklistProgress steps={revampedChecklistItemsList.length} completedSteps={revampedChecklistItemsList.filter(item => item.done).length} />
                  </div>

                  <div className="checklists h-[428px] overflow-y-auto p-4">
                    {revampedChecklistItemsList.map((item, index) =>
                      item.show ? (
                        <RevampedChecklistItem
                          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                          label={item.label}
                          // @ts-expect-error TS(2322): Type 'boolean | null | undefined' is not assignabl... Remove this comment to see the full error message
                          done={item.done}
                          isFirstUndone={index === firstUndoneIndex}
                          key={`item-${index}`}
                          onClick={() => setExpanded(e => (item.label === e ? undefined : item.label))}
                          isCTA
                          isExpanded={item.label === expanded || index === firstUndoneIndex}
                        >
                          {/*// @ts-expect-error TS(2322): Type 'Element | null' is not assignable to type 's... Remove this comment to see the full error message*/}
                          {item?.subtext}
                        </RevampedChecklistItem>
                      ) : null
                    )}
                    {revampedChecklistItemsList.find(r => !r.done) ? null : (
                      <Button
                        className="mt-4 text-base font-bold"
                        // ESLint: Do not pass children as props
                        // eslint-disable-next-line react/no-children-prop
                        children="Start Listening"
                        full
                        onClick={async () => {
                          setShowNudge(false);
                          await setCustomAccountSetting('clickedStartListening', 'true');
                          dispatch(authActions.setClickedStartListening());
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!maximizeChecklist && (
            <div className="fixed bottom-0 z-0 mb-0">
              <ChecklistMinimized revampedChecklistItemsList={revampedChecklistItemsList} onClick={() => handleVisibility(true)} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Checklist;
