import React from 'react';

const LoadingSkeleton = (props: { style?: React.CSSProperties }) => {
  return (
    <div className="webreader bg-glass-200 dark:bg-glass-800 absolute z-40" {...props}>
      <section
        style={{
          padding: 'var(--web-reader-vertical-padding) 4rem',
          marginLeft: 0,
          marginTop: 'var(--web-reader-vertical-padding)',
          width: 'var(--web-reader-max-width)',
          overflow: 'hidden'
        }}
        className="bg-white dark:bg-[#0F151E]"
      >
        <div className="mb-8 animate-pulse">
          <div className="mb-4 h-6 w-1/4 rounded-md bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-lg rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <span className="sr-only">Loading...</span>
        </div>
        <div className="mb-8 animate-pulse">
          <div className="mb-4 h-6 w-3/4 rounded-md bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-lg rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-lg rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <span className="sr-only">Loading...</span>
        </div>
        <div className="mb-8 animate-pulse">
          <div className="mb-4 h-6 w-2/5 rounded-md bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <span className="sr-only">Loading...</span>
        </div>
        <div className="mb-8 animate-pulse">
          <div className="mb-4 h-6 w-1/5 rounded-md bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-lg rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <span className="sr-only">Loading...</span>
        </div>
        <div className="mb-8 animate-pulse">
          <div className="mb-4 h-6 w-3/4 rounded-md bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-lg rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <span className="sr-only">Loading...</span>
        </div>
        <div className="undefined animate-pulse">
          <div className="mb-4 h-6 w-5/6 rounded-md bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-6xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-2xl rounded bg-gray-200 dark:bg-gray-700" />
          <div className="mb-2.5 h-4 max-w-full rounded bg-gray-200 dark:bg-gray-700" />
          <span className="sr-only">Loading...</span>
        </div>
      </section>
    </div>
  );
};

export default LoadingSkeleton;
