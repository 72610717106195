import React from 'react';

function SpeechifyLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29227 4.89856L5.91629 4.07062C6.19917 3.6953 6.66437 3.51889 7.11541 3.61591C7.46463 3.69101 7.68873 4.04407 7.61596 4.40449C7.60993 4.43431 7.60193 4.46368 7.59204 4.49239C7.07364 5.9928 6.90861 6.98381 7.09696 7.46541C7.87232 6.5146 11.8334 0.314553 13.0853 0.0202439C13.5033 -0.0780163 13.9193 0.192018 14.0145 0.623404C14.0468 0.769348 14.0387 0.921691 13.9913 1.06315C12.8083 4.59323 12.3075 6.72775 12.4888 7.46675C13.1136 6.55945 14.0415 5.33832 15.2728 3.80334C15.7352 3.22676 16.4731 2.96838 17.18 3.13542C17.8016 3.28231 18.1902 3.92149 18.0479 4.56307C18.047 4.56729 18.0461 4.5715 18.045 4.57571C17.6166 6.41469 17.4578 7.4906 17.5687 7.80349C17.6175 7.76507 17.6672 7.72565 17.7176 7.68526C18.2823 7.23292 18.9893 7.01247 19.7022 7.06647L25.2337 7.48538L21.5689 7.91547C20.5458 8.03556 19.6087 8.56388 18.9581 9.38755L18.081 10.4977C17.794 10.8611 17.3528 11.0568 16.8998 11.0215C16.4496 10.9866 16.0775 10.6458 15.9902 10.1887C15.7761 9.06605 15.7418 7.74084 15.8874 6.21315C14.787 7.9101 13.8322 10.3506 13.023 13.5347C12.8839 14.0822 12.4045 14.464 11.8561 14.464C11.3324 14.464 10.9034 14.0346 10.8873 13.4944L10.6448 5.35257L8.29948 10.1227C8.05539 10.6192 7.51831 10.8841 6.99071 10.7683C6.43175 10.6456 5.96935 10.2424 5.75877 9.69414L5.49597 9.00989C5.1241 8.0417 4.2848 7.34791 3.28813 7.18481C2.19209 7.00546 1.09604 6.82611 0 6.64675L2.44138 6.46855C3.56453 6.38656 4.60098 5.81579 5.29227 4.89856Z"
        fill="#3A62FE"
      />
    </svg>
  );
}

export default SpeechifyLogo;
