import React, { useMemo } from 'react';

import { PAGE_SIZE } from '../hooks/usePaginationState';

const DOTS = 'DOTS';

const calculatePaginationRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface PaginationParams {
  itemCount: number;
  siblingCount?: number;
  currentPage: number;
}

interface PaginationProps extends PaginationParams {
  onPageChange: (page: number) => void;
}

const usePagination = ({ itemCount, siblingCount = 1, currentPage }: PaginationParams) => {
  const paginationCalculatePaginationRange = useMemo(() => {
    // button count is determined as firstPage + lastPage + currentPage + 2*DOTS
    const buttonCount = 5;
    const totalPages = siblingCount + buttonCount;
    const totalPageCount = Math.ceil(itemCount / PAGE_SIZE);

    if (totalPages >= totalPageCount) {
      return calculatePaginationRange(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftcalculatePaginationRange = calculatePaginationRange(1, buttonCount);

      return [...leftcalculatePaginationRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = calculatePaginationRange(totalPageCount - buttonCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middlecalculatePaginationRange = calculatePaginationRange(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middlecalculatePaginationRange, DOTS, lastPageIndex];
    }
  }, [itemCount, siblingCount, currentPage]);

  return paginationCalculatePaginationRange;
};

const PaginationPill = ({
  text,
  selected = false,
  disabled = false,
  onClick
}: {
  text: string | JSX.Element;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  const colors = useMemo(() => {
    if (selected) {
      return 'bg-glass-500 text-glass-0';
    } else if (disabled) {
      return `glass-300 bg-glass-300 text-glass-350`;
    }
    return `glass-300 bg-glass-300 text-glass-700 cursor-pointer`;
  }, [selected, disabled]);

  return (
    <li className={`mx-1 flex h-8 w-8 select-none items-center justify-center rounded-lg font-ABCDiatype text-xs leading-3 ${colors}`} onClick={onClick}>
      {text}
    </li>
  );
};

const Pagination: React.FC<PaginationProps> = props => {
  const { onPageChange, siblingCount = 1, currentPage, itemCount } = props;

  const calculatePaginationRange = usePagination({
    currentPage,
    itemCount,
    siblingCount
  });

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (currentPage === 0 || calculatePaginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const lastPage = calculatePaginationRange[calculatePaginationRange.length - 1];
  return (
    <ul className="flex">
      <PaginationPill
        text={'<-'}
        onClick={() => {
          if (currentPage !== 1) {
            onPrevious();
          }
        }}
        disabled={currentPage === 1}
      />

      {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        calculatePaginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <PaginationPill key={DOTS + index} disabled text={'...'} />;
          }

          return (
            <PaginationPill
              key={pageNumber}
              text={`${pageNumber}`}
              selected={pageNumber === currentPage}
              onClick={() => onPageChange(parseInt(pageNumber + '', 10))}
            />
          );
        })
      }

      <PaginationPill
        text={'->'}
        onClick={() => {
          if (currentPage !== lastPage) {
            onNext();
          }
        }}
        disabled={currentPage === lastPage}
      />
    </ul>
  );
};

export default Pagination;
