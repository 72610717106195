import Button from 'components/elements/Button';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import ExtensionIcon from 'assets/icons/extensionPuzzleIcon';
import PinIcon from 'assets/icons/pin';
import SpeechifyLogo from 'assets/icons/SpeechifyPinLogo';
import PinExtensionAnimation from 'assets/images/pin-extension.gif';

type Props = {
  variant: 'subItem' | 'modal';
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: any;
  setModal?: React.Dispatch<React.SetStateAction<string>>;
} & React.ComponentProps<'div'>;

function PinChromeExtension({ variant, setModal, onClick }: Props) {
  const { t } = useTranslation('common');

  return (
    <div className={variant === 'subItem' ? 'checklist-subitem-wrapper' : 'checklist-modal-wrapper'}>
      {variant === 'modal' && <h4 className=" text-xl font-bold">{t('Pin Speechify Extension')}</h4>}
      <p>
        {t('Click')} <span>{<ExtensionIcon style={{ width: '0.6rem', display: 'inline' }} />}</span> {t('to the right of the address bar. Then click')}{' '}
        {<PinIcon style={{ width: '0.6rem', display: 'inline' }} />} {t('next to the')} {<SpeechifyLogo style={{ display: 'inline' }} />}
        {t('to pin it')}
      </p>
      <div className="w-[230px] overflow-hidden rounded-lg">
        <img src={PinExtensionAnimation.src} className="transform:scale(2)" />
      </div>
      <Button
        className="mt-3 text-base font-bold"
        full
        // ESLint: Do not pass children as props
        // eslint-disable-next-line react/no-children-prop
        children="Okay, got it"
        onClick={() => {
          if (variant === 'modal') {
            // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            setModal('');
          }
          onClick();
        }}
      />
    </div>
  );
}

export default PinChromeExtension;
